<vsf-layout>
    <vsf-layout-header>
        <div class="main-header shadow-lg transform shadow-xl">
            <div class="shadow-inner text-center text-sm py-2 px-2 xl:px-0" dir="rtl">
                <div class="max-w-6xl mx-2 md:mx-auto flex items-center justify-between flex-wrap	" >
                    <div dir="">
                        <span class="flex justify-center">
                        <span class="text-slate-200">
                            <span class="text-green-500">10%</span>
                             הנחה לכל ההזמנות באתר
                            
                                קוד קופון :  
                                <span class="coup font-bold">10MOTOH</span>
                                
                            
                        </span>
                        </span>
                    </div>
                    <span class="flex items-center gap-2">
                        <vsf-product-search-bar></vsf-product-search-bar>
                        <vsf-account-link class=""></vsf-account-link>
                        <!-- <vsf-cart-toggle (toggle)="openCartDrawer()"></vsf-cart-toggle> -->
                    </span>
                    <!-- <div >
                        <vsf-account-link class=""></vsf-account-link>
                    </div> -->
                </div>
            </div>
            <div class="max-w-6xl mx-2 py-2 md:mx-auto flex justify-between items-center space-x-4 " dir="rtl" >
                <span class="flex items-center w-full">
                    <vsf-mobile-menu-toggle class="md:hidden"></vsf-mobile-menu-toggle>
                    <a  [routerLink]="['/']" class=" md:hidden " >
                        <img alt="" class="mr-2" width="110" src="assets/icons/logo.png">
                    </a>
                    <vsf-collections-menu class="hidden md:block text-white w-full	"></vsf-collections-menu>
                    <vsf-cart-toggle (toggle)="openCartDrawer()"></vsf-cart-toggle>

                </span>
                <!-- <vsf-product-search-bar class=""></vsf-product-search-bar> -->
            </div>
        </div>
    </vsf-layout-header>
    <vsf-collections-menu-mobile [visible]="mobileNavVisible$ | async" class="z-10"></vsf-collections-menu-mobile>
    <vsf-cart-drawer (close)="closeCartDrawer()" [visible]="cartDrawerVisible$ | async"></vsf-cart-drawer>
    <div class="contents">
        <vsf-home-page *ngIf="isHomePage$ | async; else main"></vsf-home-page>
        <ng-template #main>
            <router-outlet></router-outlet>
        </ng-template>
    </div>

    <vsf-footer></vsf-footer>

</vsf-layout>
