

<nav class=" border-gray-200">
    <div class="max-w-screen-xl flex flex-wrap items-center mx-auto p-4 pr-0">
      <a routerLink="/" class="flex items-center space-x-3 rtl:space-x-reverse">
          <img src="assets/icons/logo.png" class="h-8" alt="Flowbite Logo" />
      </a>
      <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
          </svg>
      </button>
      <div class="hidden md:block md:w-auto" id="navbar-default">
        <ul  *ngIf="collectionTree$ | async as tree"
             class="font-medium flex flex-wrap flex-col p-4 md:p-0 mt-4 gap-1	
                   border border-gray-100 rounded-lg  
                   md:flex-row rtl:space-x-reverse 
                   md:mt-0 md:border-0  
                   dark:border-gray-700">
          <li *ngFor="let collection of tree.children" class="m-0"
              (mouseenter)="onMouseEnter(collection)"
              (mouseleave)="close($event)" >
              <!-- <span class="nav-text text-slate-200">{{ collection.name }}</span> -->
              <a    
                    [class.active]="(setActiveCollection$ | async)?.id === collection.id && overlayIsOpen$ | async"
                    (touchstart)="onTopLevelClick($event, collection)"
                    [routerLink]="['/category', collection.slug ]" 
                    class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent nav-bar
                                 md:text-blue-700 md:p-0 dark:text-white" 
                    aria-current="page">{{ collection.name }}
              </a>




          </li>

        </ul>
      </div>
    </div>
  </nav>
  



<!-- 

<div class="max-w-6xl mx-auto p-4 flex items-center">
    <ul class="flex items-center" *ngIf="collectionTree$ | async as tree">
        <li class="nav-item " *ngFor="let collection of tree.children" style="--tw-space-x-reverse: none !important;  margin-left: 15px;"
            (mouseenter)="onMouseEnter(collection)"
            (mouseleave)="close($event)">
            <button class="nav-link"
               [class.active]="(setActiveCollection$ | async)?.id === collection.id && overlayIsOpen$ | async"
               (touchstart)="onTopLevelClick($event, collection)"
               [routerLink]="['/category', collection.slug ]"
                              >

               <span class="nav-text text-slate-200">{{ collection.name }}</span>
               <span class="nav-text special-collection text-blue-600" *ngIf="collection.name === 'יד2'">
                  {{ collection.name.slice(0, -1) }} 
                <span class="yad2">2</span>
                </span>
               <span class="nav-text text-slate-200" *ngIf="collection.name !== 'יד2'">{{ collection.name }}</span>
            </button>
        </li>
    </ul>
</div> -->
<!-- <vsf-account-link></vsf-account-link> -->


<ng-template #menuTemplate>
    <div class="collections-menu-sub-collections"
         *ngIf="activeCollection?.children?.length"
         (touchstart)="captureTouchStart($event)"
         (mouseenter)="onMouseEnter(activeCollection)"
         (mouseleave)="close()"
    >
        <div class="container d-flex flex-wrap">
            <div class="level-1-block" *ngFor="let level1 of activeCollection.children">
                <vsf-collection-card [collection]="level1"></vsf-collection-card>
                <ng-container *ngFor="let level2 of level1.children">
                    <a class="level-2-link"
                       [routerLink]="['/category', level2.slug]"
                       (click)="close()">
                        {{ level2.name }}
                    </a>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>
