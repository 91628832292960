<div *ngIf="activeCustomer$ | async as customer; else notLoggedIn">
    <!-- <a class="btn btn-light text-muted" [routerLink]="['/account']">
        <fa-icon icon="user-circle"></fa-icon>
        <span class="d-none d-sm-inline ml-1">{{ userName(customer) }}</span>
    </a> -->
    <button type="button" [routerLink]="'/account/'"
    class="px-3 py-2 text-sm font-medium text-center text-white 
           bg-blue-700 rounded-lg hover:bg-blue-800  
           focus:outline-none  dark:bg-blue-600 
           dark:hover:bg-blue-700 ">
           <span class="d-none d-sm-inline ml-1">{{ userName(customer) }}</span>
           <i class="pi pi-user mr-1"></i>

</button>

</div>
<ng-template #notLoggedIn>
    <button type="button" [routerLink]="'/account/sign-in'"
                class="px-3 py-2 text-sm font-medium text-center text-white 
                       bg-blue-700 rounded-lg hover:bg-blue-800  
                       focus:outline-none  dark:bg-blue-600 
                       dark:hover:bg-blue-700 ">
                       התחברות
                       <i class="pi pi-user mr-1"></i>

    </button>
    <!-- <a class="btn btn-light text-muted text-slate-200" [routerLink]="['account/sign-in']">
        <fa-icon icon="user-circle" style="margin-left:7px;"></fa-icon>
        <span class="d-none d-sm-inline ml-1 text-slate-200">התחברות</span></a> -->
</ng-template>
