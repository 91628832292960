
<div class="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 " dir="rtl">
    <span class="">
        <img src="assets/icons/logo.png" alt="Flowbite Logo" class="h-13 mb-7">
    </span>
    <p class="text-center mb-3">כניסה ללקוחות קיימים</p>
    <p class="text-center mb-5">ברוך שובך,</p>

    <span class="divider w-9/12	m-auto"></span>

    <span class="flex justify-center mt-6 mb-4">
        <vsf-google-login-button ></vsf-google-login-button>
    </span>

    <p class="text-center mb-5">או</p>
    <p class="text-center mb-3">כניסה באמצעות אימייל:</p>
    
    <form #signInForm="ngForm"
        class="space-y-6"
        (submit)="signIn()">
        <div>
            <label for="email" class="block text-sm font-medium text-gray-700">אימייל:</label>
            <div class="mt-1">
                <input [(ngModel)]="emailAddress" id="email" name="email" type="email" autocomplete="email" required
                    class="input-text">
            </div>
        </div>

        <div>
            <label for="password" class="block text-sm font-medium text-gray-700">סיסמא:</label>
            <div class="mt-1">
                <input [(ngModel)]="password" id="password" name="password" type="password"
                    autocomplete="current-password" required
                    class="input-text">
            </div>
        </div>

        <div class="flex items-center justify-between">
            <div class="flex items-center gap-2">
                <input id="remember-me" name="remember-me" type="checkbox"
                    [(ngModel)]="rememberMe"
                    class="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500">
                <label for="remember-me" class="ml-2 block text-sm text-gray-900">זכור אותי</label>
            </div>

            <div class="text-sm">
                <a [routerLink]="['/account/forgotten-password', { email: this.emailAddress ?? '' }]"
                class="font-medium text-primary-600 hover:text-primary-500">שכחת סיסמא ?</a>
            </div>
        </div>
            <button type="submit"
                    [disabled]="signInForm.invalid || signInForm.pristine"
                    class="w-full btn-primary">
                כניסה
            </button>
        <div *ngIf="displayRegisterLink" class="text-sm font-medium text-primary-600 
                    hover:text-primary-500 mx-auto text-center">
            <a [routerLink]="['/account/register']">אין ברשותך חשבון ? הירשם עכשיו</a>
        </div>
    </form>
</div>
