import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'vsf-google-login-button',
  templateUrl: './google-login-button.component.html',
  styleUrls: ['./google-login-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleLoginButtonComponent {

}
