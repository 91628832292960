
<vsf-layout-footer>
    <footer
        aria-labelledby="footer-heading"
        class="mt-24 border-t main-header"
        dir="rtl"
    >
        <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 ">
            <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                <div class="grid grid-cols-2 gap-8 xl:col-span-2">
                    <div class="md:grid md:grid-cols-2 md:gap-8">
                        <div>
                            <h3 class="text-sm font-semibold text-slate-200 tracking-wider uppercase">
                                חנות
                            </h3>
                            <ul class="mt-4 space-y-4" role="list">
                                <li *ngFor="let item of topCollections$ | async">
                                    <a
                                        [routerLink]="['/category', item.slug]"
                                        class="text-base text-slate-200	 hover:text-slate-600"
                                    >
                                        {{item.name}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-12 md:mt-0">
                            <h3 class="text-sm font-semibold text-slate-200 tracking-wider uppercase">
                                תמיכה
                            </h3>
                            <ul class="mt-4 space-y-4" role="list">
                                <li *ngFor="let item of navigation.support">
                                    <a
                                        [href]="item.href"
                                        class="text-base text-slate-200 hover:text-slate-600"
                                    >
                                        {{item.name}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="md:grid md:grid-cols-2 md:gap-8">
                        <div>
                            <h3 class="text-sm font-semibold text-slate-200 tracking-wider uppercase">
                                מוטו-הייטק
                            </h3>
                            <ul class="mt-4 space-y-4" role="list">
                                <li *ngFor="let item of navigation.company">
                                    <a
                                        [href]="item.href"
                                        class="text-base text-slate-200 hover:text-slate-600"
                                    >
                                        {{item.name}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="mt-8 xl:mt-0">
                   
                    <img width="185" src="assets/icons/logo.png">
                    <p class="mt-3 text-slate-200"> מוסך מורשה משרד תחבורה מ.ר 41085</p>

                    <p class="text-slate-200">שעות פעילות: ימי א'-ה' 08:30-17:00</p>


                    <p class="text-slate-200">
                    <i class="pi pi-map-marker ml-1" style="font-size: 0.75rem"></i>
                        כתובת: רחוב העבודה 67, א.תעשיה, אשדוד.
                    </p>
                    <p class="text-slate-200">
                        <i class="pi pi-phone ml-1" style="font-size: 0.75rem"></i>
                        טלפון מוסך: 08-8604189.
                    </p>
                    <p class="text-slate-200">
                        <i class="pi pi-file ml-1" style="font-size: 0.75rem"></i>
                        פקס מוסך: 08-8526034. 
                    </p>
                    <p class="text-slate-200">
                        <i class="pi pi-phone ml-1" style="font-size: 0.75rem"></i>
                        טלפון תמיכה חנות אינטרנט: 054-4389616
                    </p>
                    <p class="text-slate-200">
                        <i class="pi pi-envelope ml-1" style="font-size: 0.75rem"></i>
                        אימייל תמיכה חנות אינטרנט: 
                        <a href="mailto:admin@moto-hitech.com" class="underline text-blue-800">admin&#64;moto-hitech.com</a>
                    </p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3390.605336706697!2d34.66799975991541!3d31.80850427419134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1502a340f099148f%3A0x588fdc7b1c8ab849!2z157XldeY15Ug15TXmdeZ15jXpw!5e0!3m2!1siw!2sil!4v1734309469001!5m2!1siw!2sil" 
                            width="300" height="250" style="border:0;" allowfullscreen="" 
                            loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                            class="mt-4 rounded-2xl	">
                    </iframe>
                    <!-- <form class="mt-4 sm:flex sm:max-w-md">
                        <label class="sr-only" htmlFor="email-address">
                            Email address
                        </label>
                        <input
                            autoComplete="email"
                            class="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
                            id="email-address"
                            name="email-address"
                            placeholder="Enter your email"
                            required
                            type="email"
                        />
                        <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                            <button
                                class="w-full bg-primary-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary-500"
                                type="submit"
                            >
                                Subscribe
                            </button>
                        </div>
                    </form> -->
                </div>
            </div>

            <div class="mt-8 border-t pt-8" dir="ltr">

                <span class="devop text-slate-200">
                    <span> 
                        <span>Powered by </span> 
                        <a class="end-line" target="_blank"
                        href="https://github.com/idanzam" >
                            <span>Idan Zamir-Halevy</span>
                                
                        </a>
                        <span> 2024© |</span> 
                    </span>
<!-- 
                    <a class="end-line" target="_blank"
                       href="https://github.com/idanzam" >
                       Idan Zamir-Halevy              
                    </a> -->
                    <img width="85" src="assets/icons/angular.png">
                </span>
                
            </div>
        </div>
    </footer>
</vsf-layout-footer>
